document.addEventListener('DOMContentLoaded', function () {
    $('.section_lifehacks_list').on('init', function () {
        let max_h = 0;
        $('.section_lifehacks_list .section_lifehacks_list_item').each(function () {
            if ($(this).height() > max_h) {
                max_h = $(this).height();
            }
        });
        $('.section_lifehacks_list .section_lifehacks_list_item').height(max_h);
    });

    $('.section_lifehacks_list').slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        focusOnSelect: true,
    });
});
